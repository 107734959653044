import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

import { AuthContext } from "../../App";

import headerImg from "../../images/icrpc-logo-black-200px.jpg";
const HomePage: React.FC = () => {
  const authContext = React.useContext(AuthContext);
  const location = useLocation();

  return (
    <Container>
      <Row>
        <Col>
          <div className="text-center my-2">
            <Image
              src={headerImg}
              alt="ICRPC Logo"
              style={{ maxHeight: "100px" }}
              fluid
            />
          </div>
          <p className="lead">Welcome to the ICRPC Members Portal</p>

          <p>
            This application is for members of Imperial College &amp; St. Mary's
            Rifle &amp; Pistol Club to perform actions related to their
            membership, such as updating their information and renewing their
            Club membership at the start of a new year.
          </p>
        </Col>
      </Row>
      {authContext.authState.id !== null ? (
        <>
          <hr />
          <Row>
            <Col>
              <p className="lead">
                Please choose from one of the following options:
              </p>
            </Col>
          </Row>
          <Row>
            {[
              {
                buttonText: "Your details",
                description: "View and edit your member record",
                link: "/memberrecord",
              },
              {
                buttonText: "Renew Club membership",
                description:
                  "Renew your Club membership at the start of a new year",
                link: "/renew",
              },
            ].map(({ buttonText, description, link }, i, a) => (
              <Col
                xs={12}
                md={a.length >= 2 ? 6 : 12}
                lg={a.length >= 3 ? 4 : a.length >= 2 ? 6 : 12}
                key={i}
              >
                <Alert variant="secondary">
                  {link !== undefined ? (
                    <LinkContainer to={link}>
                      <Button variant="primary" className="w-100">
                        {buttonText}
                      </Button>
                    </LinkContainer>
                  ) : (
                    <Button variant="secondary" className="w-100" disabled>
                      {buttonText}
                    </Button>
                  )}

                  <div className="mt-2">{description}</div>
                </Alert>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <div style={{ width: "100%", maxWidth: "570px", margin: "0 auto" }}>
          <LinkContainer to="/login" state={{ next: location.pathname }}>
            <Button variant="primary" className="w-100">
              Login
            </Button>
          </LinkContainer>
        </div>
      )}
    </Container>
  );
};

export default HomePage;
