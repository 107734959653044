import React from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { authLogout } from "../../api";

import { AuthContext } from "../../App";

const Header: React.FC = () => {
  const authContext = React.useContext(AuthContext);

  const handleLogout = () => {
    authLogout().finally(() => {
      authContext.setAuthState({ id: null, next: null, setNext: false });
    });
  };

  return (
    <Navbar bg="light" variant="light">
      <Container>
        <Navbar.Brand as={Link} to="/" className="mr-auto">
          <span className="icrpc-title">ICRPC Members Portal</span>
        </Navbar.Brand>

        {authContext.authState.id !== null && (
          <Nav className="justify-content-end">
            <Navbar.Text>
              Logged in as {authContext.authState.id.name}
            </Navbar.Text>
            <Nav.Link active href="#" onClick={() => handleLogout()}>
              Logout
            </Nav.Link>
          </Nav>
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
