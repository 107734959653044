import React from "react";
import { BrowserRouter } from "react-router-dom";

import Routes from "./Routes";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

import "./App.scss";

type AuthID = {
  cid: string;
  name: string;
};

export type AuthState = {
  id: AuthID | null;
  next: string | null;
  setNext?: boolean;
};

interface AuthContextValues {
  authState: AuthState;
  setAuthState: (authState: AuthState) => void;
}

export const AuthContext = React.createContext<AuthContextValues>({
  authState: { id: null, next: null },
  setAuthState: () => undefined,
});

const AUTH_STORAGE_KEY = "ICRPC_MEMPORTAL_AUTHID";

const useAuthState = (): {
  authState: AuthState;
  setAuthState: (authState: AuthState) => void;
} => {
  const storedAuthIdJson = sessionStorage.getItem(AUTH_STORAGE_KEY);

  const authID: AuthID | null = (() => {
    try {
      if (storedAuthIdJson !== null) {
        const jsonParsed = JSON.parse(storedAuthIdJson);

        if (
          jsonParsed.hasOwnProperty("cid") &&
          jsonParsed.hasOwnProperty("name")
        ) {
          return {
            cid: jsonParsed.cid,
            name: jsonParsed.name,
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch {
      return null;
    }
  })();

  const [authState, setAuthState] = React.useState<AuthState>({
    id: authID,
    next: null,
  });

  React.useEffect(() => {
    if (authState.id !== null) {
      sessionStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(authState.id));
    } else {
      sessionStorage.removeItem(AUTH_STORAGE_KEY);
    }
  }, [authState.id]);

  return { authState, setAuthState };
};

const App = () => {
  const { authState, setAuthState } = useAuthState();

  return (
    <BrowserRouter>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <header>
          <Header />
        </header>
        <main>
          <Routes />
        </main>
        <Footer />
      </AuthContext.Provider>
    </BrowserRouter>
  );
};

export default App;
