import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { Link } from "react-router-dom";

const PageNotFoundPage: React.FC = () => (
  <Container>
    <Row>
      <Col>
        <h1>Page Not Found</h1>
        <p className="lead">
          <Link to="/">Click here to go to the homepage</Link> or use your
          browser's back button to return to the previous page.
        </p>
        <p>
          If you got here from a link elsewhere on the site, please contact the
          Club detailing how you arrived here so the link can be fixed.
        </p>
      </Col>
    </Row>
  </Container>
);

export default PageNotFoundPage;
