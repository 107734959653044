import React from "react";
import { useLocation } from "react-router-dom";
import { Formik, Field, FieldProps } from "formik";
import * as Yup from "yup";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { AuthContext } from "../../App";
import { authRequest, authToken } from "../../api";

const VALID_NEXT_PATHS = ["/", "/memberrecord", "/renew"];

const isValidNext = (url: string) => {
  return VALID_NEXT_PATHS.includes(url);
};

const LoginSchema = Yup.object().shape({
  cid: Yup.string().required("CID is required"),
  dob: Yup.date().required("Date of Birth is required"),
});

const LoginPage: React.FC = () => {
  const [formError, setFormError] = React.useState<string | null>(null);
  const [authRequestSucceeded, setAuthRequestSucceeded] = React.useState(false);
  const [tokenRequestEmailHint, setTokenRequestEmailHint] = React.useState("");

  const authContext = React.useContext(AuthContext);

  const location = useLocation();

  const handleAuthRequest = (
    cid: string,
    dob: string,
    cb: (err: string | null) => void
  ) => {
    const locationState = location.state as Record<string, any> | undefined;

    authRequest(cid, dob, locationState?.next || null)
      .then(({ success, emailHint }) => {
        if (success) {
          setTokenRequestEmailHint(emailHint);

          cb(null);
        }
      })
      .catch((error) => cb(error));
  };

  const handleAuthToken = React.useCallback(
    (emailToken: string, cb: (err: string | null) => void) => {
      authToken(emailToken)
        .then((authData) => {
          if (authData !== null) {
            cb(null);

            const next = new URLSearchParams(location.search).get("next");

            authContext.setAuthState({
              id: authData,
              next: next !== null && isValidNext(next) ? next : "/",
            });
          } else {
            cb("Token not accepted by server");
          }
        })
        .catch((error) => cb(error));
    },
    [authContext, location.search]
  );

  const emailToken = new URLSearchParams(location.search).get("token");

  React.useEffect(() => {
    if (emailToken !== null) {
      handleAuthToken(emailToken, (err) => {
        if (err !== null) {
          console.error(err);
        }
      });
    }
  }, [emailToken, handleAuthToken]);

  return emailToken !== null ? (
    <Container>
      <Row>
        <Col>
          <div style={{ width: "100%", maxWidth: "570px", margin: "0 auto" }}>
            <Alert variant="secondary" style={{ marginTop: "1rem" }}>
              Verifying token...
            </Alert>
          </div>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container>
      <Row>
        <Col>
          <div className="h1" style={{ marginTop: "1rem" }}>
            Login
          </div>

          <hr />
          <div style={{ width: "100%", maxWidth: "570px", margin: "0 auto" }}>
            {authRequestSucceeded ? (
              <>
                <Alert variant="success">
                  <p className="lead">
                    An email has been sent to{" "}
                    <strong>{tokenRequestEmailHint}</strong> with a link to
                    login.
                  </p>
                  <p>
                    If you do not receive the email within the next 10 minutes,
                    please try again before contacting the club. Remember to
                    check your spam/junk folder.
                  </p>
                </Alert>
              </>
            ) : (
              <>
                <div>
                  <p className="lead">
                    Please confirm your identity to continue
                  </p>
                </div>

                <Formik
                  initialValues={{ cid: "", dob: "" }}
                  validationSchema={LoginSchema}
                  onSubmit={(values, actions) => {
                    setFormError(null);
                    handleAuthRequest(values.cid, values.dob, (error) => {
                      // Don't bother setting submitting to false if there's not an
                      // error as we're navigating away from the page anyway
                      if (error !== null) {
                        setFormError(error);
                        actions.setSubmitting(false);
                      } else {
                        setAuthRequestSucceeded(true);
                      }
                    });
                  }}
                >
                  {(formProps) => (
                    <Form
                      onSubmit={(e: React.FormEvent<HTMLElement>) =>
                        formProps.handleSubmit(
                          e as React.FormEvent<HTMLFormElement>
                        )
                      }
                    >
                      <Field name="cid">
                        {({ field, meta }: FieldProps) => (
                          <Form.Group className="mb-3">
                            <Form.Label>CID</Form.Label>
                            <Form.Control
                              {...field}
                              type="text"
                              isInvalid={meta.touched && !!meta.error}
                            />
                            <Form.Text className="text-muted">
                              Please include all leading zeroes. If you do not
                              know your CID, you can find it on the{" "}
                              <a
                                href="https://www.imperialcollegeunion.org/user"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Imperial College Union
                              </a>{" "}
                              website
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">
                              {meta.error}
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                      </Field>

                      <Field name="dob">
                        {({ field, meta }: FieldProps) => (
                          <Form.Group className="mb-3">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                              {...field}
                              type="date"
                              isInvalid={meta.touched && !!meta.error}
                            />
                            <Form.Control.Feedback type="invalid">
                              {meta.error}
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                      </Field>

                      {formError !== null && (
                        <Alert variant="danger">
                          <strong>Error:</strong> {formError}
                        </Alert>
                      )}

                      <Button
                        className="w-100"
                        type="submit"
                        disabled={formProps.isSubmitting}
                        style={{ marginBottom: "1rem" }}
                      >
                        Continue
                      </Button>

                      <Alert variant="secondary">
                        If you are experiencing issues logging in, please
                        contact the club
                      </Alert>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </div>

          <div
            style={{ width: "100%", maxWidth: "570px", margin: "0 auto" }}
          ></div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
