import React from "react";

import moment from "moment";

const Footer: React.FC = () => (
  <footer>
    <div className="text-center small">
      &copy; Imperial College &amp; St. Mary's Rifle &amp; Pistol Club
      2020&ndash;{moment().utc().year()}
    </div>
    <div className="text-center text-muted" style={{ fontSize: "0.75rem" }}>
      <b>FE</b>: {process.env.NODE_ENV} -{" "}
      {process.env.REACT_APP_ICRPC_VERSION || "dev"}
    </div>
  </footer>
);

export default Footer;
