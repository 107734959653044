import React from "react";
import {
  Navigate,
  Route,
  Routes as RouterRoutes,
  useLocation,
} from "react-router-dom";

import { authInfo } from "./api";

import { AuthContext, AuthState } from "./App";

import HomePage from "./components/pages/HomePage";
import LoginPage from "./components/pages/LoginPage";
import MemberRecordPage from "./components/pages/MemberRecordPage";
import PageNotFoundPage from "./components/pages/PageNotFoundPage";
import RenewPage from "./components/pages/RenewPage";

interface RestrictedRouteProps {
  children: React.ReactNode;
}

const useProtectedRoutes = (
  authState: AuthState,
  loginPath: string,
  defaultPath: string
) => {
  const location = useLocation();

  const isAuthenticated = authState.id !== null;
  const setNext = authState.setNext ?? true;

  const RequireAnon: React.FC<RestrictedRouteProps> = ({ children }) =>
    isAuthenticated ? (
      <Navigate
        to={authState.next !== null ? authState.next : defaultPath}
        replace
      />
    ) : (
      <>{children}</>
    );

  const RequireAuth: React.FC<RestrictedRouteProps> = ({ children }) =>
    isAuthenticated ? (
      <>{children}</>
    ) : (
      <Navigate
        to={loginPath}
        state={setNext ? { next: location.pathname } : null}
        replace
      />
    );

  return { RequireAnon, RequireAuth };
};

const Routes: React.FC = () => {
  const location = useLocation();

  const authContext = React.useContext(AuthContext);

  const updateAuthState = React.useCallback(() => {
    authInfo()
      .then((authID) => {
        if (
          (authID === null && authContext.authState.id !== null) ||
          (authID !== null &&
            (authContext.authState.id === null ||
              authID.cid !== authContext.authState.id.cid))
        ) {
          authContext.setAuthState({ id: authID, next: null });
        }
      })
      .catch(() => authContext.setAuthState({ id: null, next: null }));
  }, [authContext]);

  React.useEffect(() => {
    updateAuthState();

    const authTimer = setInterval(() => {
      updateAuthState();
    }, 30 * 1000);

    // Cancel the interval when clearing up
    return () => clearInterval(authTimer);
  }, [authContext, location.pathname, updateAuthState]);

  const { RequireAnon, RequireAuth } = useProtectedRoutes(
    authContext.authState,
    "/login",
    "/"
  );

  return (
    <RouterRoutes>
      <Route path="/" element={<HomePage />} />

      <Route
        path="/login"
        element={
          <RequireAnon>
            <LoginPage />
          </RequireAnon>
        }
      />

      <Route
        path="/memberrecord"
        element={
          <RequireAuth>
            <MemberRecordPage />
          </RequireAuth>
        }
      />
      <Route
        path="/renew"
        element={
          <RequireAuth>
            <RenewPage />
          </RequireAuth>
        }
      />

      <Route path="*" element={<PageNotFoundPage />} />
    </RouterRoutes>
  );
};

export default Routes;
