import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Address, MemberRecord } from "icrpc-members-portal-common";

import { getMemberRecord } from "../../api";

import passportPhotoPlaceholderImg from "../../images/passport-photo-placeholder.jpg";

const displayDate = (date: string | null, nullDateText?: string) =>
  date !== null && /\d{4}-\d{2}-\d{2}/.test(date) ? (
    moment.utc(date).format("Do MMMM YYYY")
  ) : (
    <i className="text-muted">{nullDateText || "N/A"}</i>
  );

interface AddressDisplayProps {
  address: Address;
}

const AddressDisplay: React.FC<AddressDisplayProps> = ({ address }) => (
  <div style={{ marginBottom: "1rem" }}>
    <div style={{ marginBottom: "0.2rem" }}>
      {address.line1}
      <br />
      {address.line2 !== null && (
        <>
          {address.line2}
          <br />
        </>
      )}
      {address.town}
      <br />
      {address.region !== null && (
        <>
          {address.region}
          <br />
        </>
      )}
      {address.postcode !== null && (
        <>
          {address.postcode}
          <br />
        </>
      )}
      {address.country}
    </div>

    <div style={{ marginBottom: "0.2rem" }}>
      <div>
        <strong style={{ fontSize: "0.9rem" }}>Dates</strong>
      </div>
      <div>
        {displayDate(address.dateFrom)}&ndash;
        {address.dateTo !== null ? displayDate(address.dateTo) : <i>Present</i>}
      </div>
    </div>

    <div style={{ marginBottom: "0.2rem" }}>
      <div>
        <strong style={{ fontSize: "0.9rem" }}>Address Type</strong>
      </div>
      <div>
        {address.addressType === "PERM"
          ? "Permanent"
          : address.addressType === "TEMP"
          ? "Temporary"
          : "Unknown"}
      </div>
    </div>
  </div>
);

const MemberRecordPage: React.FC = () => {
  const [memberRecord, setMemberRecord] = React.useState<
    MemberRecord | undefined
  >(undefined);

  React.useEffect(() => {
    getMemberRecord().then((record) => {
      setMemberRecord(record);
    });
  }, []);

  const primaryAddress: Address | undefined = (() => {
    const pas = memberRecord?.addresses.filter((a) => a.isPrimary);
    return pas !== undefined && pas.length > 0 ? pas[0] : undefined;
  })();

  const otherAddresses: Address[] =
    memberRecord?.addresses.filter((a) => !a.isPrimary) || [];

  return (
    <Container>
      <Row>
        <Col>
          <h1>Member Record</h1>

          <div>
            <Link to="/">
              <FontAwesomeIcon icon={faArrowLeft} /> Back to home
            </Link>
          </div>

          <hr />

          <p className="lead">View your member profile here</p>
          <Alert variant="warning">
            Profile editing is currently disabled&mdash;if any details are
            incorrect please email the club.
          </Alert>
        </Col>
      </Row>
      {memberRecord !== undefined ? (
        <>
          <Row>
            <Col md={{ order: 2, span: 3 }}>
              <h2 className="h3">Photo</h2>
              <div className="text-center">
                <Image
                  src={passportPhotoPlaceholderImg}
                  fluid
                  style={{ maxHeight: "350px" }}
                />
              </div>
            </Col>
            <Col md={{ order: 1, span: 9 }}>
              <h2 className="h3">Identity</h2>
              <Table size="sm">
                <tbody>
                  <tr>
                    <th scope="row">Forenames</th>
                    <td>{memberRecord.forenames}</td>
                  </tr>
                  <tr>
                    <th scope="row">Surname</th>
                    <td>{memberRecord.surname}</td>
                  </tr>
                  <tr>
                    <th scope="row">Preferred Name</th>
                    <td>
                      {memberRecord.preferredName ?? (
                        <i className="text-muted">N/A</i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Date of Birth</th>
                    <td>{displayDate(memberRecord.dateOfBirth)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Place of Birth</th>
                    <td>
                      {memberRecord.townOfBirth}, {memberRecord.countryOfBirth}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Nationality</th>
                    <td>{memberRecord.nationality}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="h3">Contact</h2>
              <Table size="sm">
                <tbody>
                  <tr>
                    <th scope="row">Email Address</th>
                    <td>{memberRecord.emailAddress}</td>
                  </tr>
                  <tr>
                    <th scope="row">Contact Number</th>
                    <td>{memberRecord.contactNumber}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="h3">Addresses</h2>
              <Table size="sm">
                <tbody>
                  {primaryAddress !== undefined && (
                    <tr>
                      <th scope="row">Primary Address</th>
                      <td>
                        <AddressDisplay address={primaryAddress} />
                      </td>
                    </tr>
                  )}

                  {otherAddresses.length > 0 && (
                    <tr>
                      <th scope="row">Other Addresses</th>
                      <td>
                        {otherAddresses
                          .sort((a, b) => moment(b.dateFrom).diff(a.dateFrom))
                          .map((a, i) => (
                            <AddressDisplay key={i} address={a} />
                          ))}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="h3">College Info</h2>
              <Table size="sm">
                <tbody>
                  <tr>
                    <th scope="row">CID</th>
                    <td style={{ fontFamily: "monospace" }}>
                      {memberRecord.cid}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Status</th>
                    <td>
                      {memberRecord.icuStatus === "STUDENT" ? (
                        <Badge bg="primary">Student</Badge>
                      ) : memberRecord.icuStatus === "ASSOCIATE" ? (
                        <Badge bg="success">Associate</Badge>
                      ) : (
                        memberRecord.icuStatus
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">College Login</th>
                    <td>
                      {(
                        <span style={{ fontFamily: "monospace" }}>
                          {memberRecord.collegeLogin}
                        </span>
                      ) ?? <i className="text-muted">N/A</i>}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="h3">Club Info</h2>
              <Table size="sm">
                <tbody>
                  <tr>
                    <th scope="row">Club Status</th>
                    <td>
                      {memberRecord.clubStatus === "FULL" ? (
                        <Badge bg="success">Full Member</Badge>
                      ) : memberRecord.clubStatus === "PROBATIONARY" ? (
                        <Badge bg="primary">Probationary Member</Badge>
                      ) : memberRecord.clubStatus === "APPLICANT" ? (
                        <Badge bg="secondary">Applicant</Badge>
                      ) : memberRecord.clubStatus === "BANNED" ? (
                        <Badge bg="danger">Banned</Badge>
                      ) : (
                        memberRecord.clubStatus
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Application Received Date</th>
                    <td>{displayDate(memberRecord.applicationDate)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Join Date</th>
                    <td>
                      {displayDate(
                        memberRecord.joinDate,
                        "Not joined yet - still an applicant"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Leave Date</th>
                    <td>
                      {displayDate(
                        memberRecord.leaveDate,
                        "Not left - still a current member"
                      )}
                      &nbsp;
                      {memberRecord.leaveDate !== null &&
                        (memberRecord.leaveDate >=
                        moment().format("YYYY-MM-DD") ? (
                          <Badge bg="success">Current</Badge>
                        ) : (
                          <Badge bg="warning" text="dark">
                            Left
                          </Badge>
                        ))}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="h3">FAC/SGC</h2>
              <Table size="sm">
                <tbody>
                  {[
                    {
                      type: "FAC",
                      cert: memberRecord.fac,
                    },
                    {
                      type: "SGC",
                      cert: memberRecord.sgc,
                    },
                  ].map(({ type, cert }, i) => (
                    <tr key={i}>
                      <th scope="row">{type}</th>
                      <td>
                        {cert !== null ? (
                          <div>
                            {(() => {
                              const vs = [
                                {
                                  k: "Certificate Number",
                                  v: (
                                    <span style={{ fontFamily: "monospace" }}>
                                      {cert.certificateNumber}
                                    </span>
                                  ),
                                },
                                {
                                  k: "Issuing Force",
                                  v: cert.issuingForce,
                                },
                                {
                                  k: "Expiry Date",
                                  v: displayDate(cert.expiryDate),
                                },
                              ];
                              if (type === "FAC") {
                                vs.push({
                                  k: "Conditional on ICRPC membership",
                                  v: (cert as typeof memberRecord.fac)
                                    ?.icrpcCondition
                                    ? "Yes"
                                    : "No",
                                });
                              }
                              return vs;
                            })().map(({ k, v }, i) => (
                              <div key={i} style={{ marginBottom: "0.3rem" }}>
                                <strong style={{ fontSize: "0.9rem" }}>
                                  {k}
                                </strong>
                                <br />
                                {v}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <i className="text-muted">No {type} on record</i>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col>Loading...</Col>
        </Row>
      )}
    </Container>
  );
};

export default MemberRecordPage;
