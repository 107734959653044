import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { getMembershipStatus, renew } from "../../api";

interface RenewalComponentProps {
  renewalBag: {
    nextStage: () => void;
    renewalToken: string | null;
    setRenewalToken: React.Dispatch<React.SetStateAction<string | null>>;
  };
}

const MembershipCheck: React.FC<RenewalComponentProps> = ({
  renewalBag: { nextStage, setRenewalToken },
}) => {
  const [icuCheckInProgress, setIcuCheckInProgress] = React.useState(false);
  const [rejectError, setRejectError] = React.useState<string | null>(null);

  const handleICUCheck = () => {
    setRejectError(null);
    setIcuCheckInProgress(true);

    getMembershipStatus()
      .then(
        ({
          renewalToken,
          clubStatus,
          currentICUMember,
          canRenewMembership,
        }) => {
          if (renewalToken !== null) {
            setRenewalToken(renewalToken);
            nextStage();
          } else {
            if (clubStatus === "APPLICANT") {
              setRejectError(
                "You are an applicant and so cannot renew as you have not joined the club yet"
              );
            } else if (clubStatus === "BANNED") {
              setRejectError(
                "You are banned from the club and so cannot renew"
              );
            } else if (!canRenewMembership) {
              setRejectError(
                "The current date is outside of your renewal period - please contact the club"
              );
            } else if (!currentICUMember) {
              setRejectError(
                "You have not purchased membership for this year - if you have just purchased membership try again in 15 minutes"
              );
            } else {
              setRejectError(
                "Your membership status is not eligible for renewal - please contact the club"
              );
            }

            setIcuCheckInProgress(false);
          }
        }
      )
      .catch((reason) => {
        setRejectError(reason);
        setIcuCheckInProgress(false);
      });
  };

  return (
    <>
      <Alert variant="warning">
        <p>
          <strong>Important:</strong> In order to renew ICRPC membership, you
          must have first purchased membership for this year on the Union's
          website.
        </p>
        <Button
          variant="secondary"
          className="w-100"
          as="a"
          href="https://www.imperialcollegeunion.org/activities/a-to-z/rifle-pistol"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to visit the Union's website
        </Button>
      </Alert>

      <hr />

      <p className="lead">
        Once you have purchased club membership, click below to verify your
        membership status and continue.
      </p>

      {rejectError !== null && (
        <Alert variant="danger">
          <p>
            <strong>Membership check failed: </strong> you cannot renew for the
            following reason(s):
          </p>
          <p>
            <strong>{rejectError}</strong>
          </p>
        </Alert>
      )}

      <Button
        variant="primary"
        className="w-100"
        onClick={() => handleICUCheck()}
        disabled={icuCheckInProgress}
      >
        {icuCheckInProgress ? "Verifying..." : "Verify Membership"}
      </Button>
    </>
  );
};

const RenewForm: React.FC<RenewalComponentProps> = ({
  renewalBag: { nextStage, renewalToken },
}) => {
  const [renewalInProgress, setRenewalInProgress] = React.useState(false);
  const [renewalError, setRenewalError] = React.useState<string | null>(null);
  const [confirmTicked, setConfirmTicked] = React.useState(false);

  const handleRenew = () => {
    setRenewalInProgress(true);
    setRenewalError(null);

    renew(confirmTicked, renewalToken)
      .then(() => {
        nextStage();
      })
      .catch((reason) => {
        setRenewalInProgress(false);
        setRenewalError(reason);
      });
  };

  return (
    <div>
      <p className="lead">
        To renew your Club membership, please read and confirm the following
        declarations:
      </p>
      <ul>
        <li>
          I agree to abide by the Constitution and Byelaws of Imperial College
          &amp; St. Mary's Rifle &amp; Pistol Club.
        </li>
        <li>
          I have not been diagnosed with or treated for a medical condition
          (physical or mental) which might affect my suitability to possess
          firearms, including but not limited to:
          <ul>
            <li>
              Acute Stress Reaction or an acute reaction to the stress caused by
              a trauma
            </li>
            <li>Suicidal thoughts or self harm</li>
            <li>Depression or anxiety</li>
            <li>Dementia</li>
            <li>Mania, bipolar disorder or a psychotic illness</li>
            <li>A personality disorder</li>
            <li>
              A neurological condition: for example, Multiple Sclerosis,
              Parkinson’s or Huntington’s diseases, or epilepsy
            </li>
            <li>Alcohol or drug abuse</li>
            <li>
              Any other mental or physical condition which might affect your
              safe possession of a firearm or shotgun
            </li>
          </ul>
        </li>
        <li>
          I have never had an application for a Firearm or Shotgun Certificate
          refused, nor had such a certificate revoked.
        </li>
        <li>
          I am not prohibited from possessing firearms by virtue of Section 21
          of the Firearms Act 1968 (as amended).
          <ul>
            <li>
              Section 21 applies to anybody who has been sentenced to
              imprisonment or to youth custody or to detention in a Young
              Offender's Institution.{" "}
              <strong>This includes suspended sentences</strong>.
            </li>
            <li>
              The period of prohibition varies depending on the length of the
              sentence.
            </li>
            <li>
              Please see{" "}
              <a
                href="https://www.legislation.gov.uk/ukpga/1968/27/section/21"
                target="_blank"
                rel="noopener noreferrer"
              >
                legislation.gov.uk
              </a>{" "}
              (opens in a new window) for the full text.
            </li>
          </ul>
        </li>
      </ul>
      <Alert variant="info">
        <p>
          If you think you may be affected by any of the items listed above, or
          if you have any questions, <em>please</em> contact the Club and we can
          advise you.
        </p>
      </Alert>
      <hr />
      {renewalError !== null && (
        <Alert variant="danger">
          <strong>Renewal failed: </strong>
          {renewalError}
        </Alert>
      )}
      <Form style={{ marginBottom: "2rem", textAlign: "center" }}>
        <Form.Group
          className="d-inline-block mb-3"
          controlId="confirmDeclarations"
        >
          <Form.Check
            type="checkbox"
            label="I confirm the above declarations are true"
            checked={confirmTicked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setConfirmTicked(e.target.checked);
            }}
          />
        </Form.Group>

        <Button
          className="w-100"
          onClick={() => handleRenew()}
          disabled={!confirmTicked || renewalInProgress}
        >
          {renewalInProgress ? "Renewing..." : "Renew"}
        </Button>
      </Form>
    </div>
  );
};

const ThanksMessage: React.FC<RenewalComponentProps> = () => (
  <Alert variant="success">
    <div style={{ textAlign: "center" }}>
      <p className="lead">Thank you for renewing your ICRPC membership!</p>
      <p>Please take a couple of minutes to check your details are correct.</p>
      <LinkContainer to="/memberrecord">
        <Button className="w-100" variant="success">
          View Profile
        </Button>
      </LinkContainer>
    </div>
  </Alert>
);

const RenewPage: React.FC = () => {
  const [renewalStage, setRenewalStage] = React.useState(0);
  const [renewalToken, setRenewalToken] = React.useState<string | null>(null);

  const renewalBag = {
    nextStage: () => setRenewalStage(renewalStage + 1),
    renewalToken,
    setRenewalToken,
  };

  const renewalStages = [
    <MembershipCheck renewalBag={renewalBag} />,
    <RenewForm renewalBag={renewalBag} />,
    <ThanksMessage renewalBag={renewalBag} />,
  ];

  return (
    <Container>
      <Row>
        <Col>
          <div className="h1">Renew ICRPC Membership</div>

          <div>
            <Link to="/">
              <FontAwesomeIcon icon={faArrowLeft} /> Back to home
            </Link>
          </div>

          <hr />

          <div style={{ width: "100%", maxWidth: "720px", margin: "0 auto" }}>
            {renewalStages[renewalStage]}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RenewPage;
