import axios from "axios";

import { MemberRecord, MembershipStatus } from "icrpc-members-portal-common";

export const getMembershipStatus = () => {
  return new Promise<MembershipStatus>((resolve, reject) => {
    axios
      .get("/api/membershipstatus")
      .then((response) => resolve(response.data as MembershipStatus))
      .catch((err) =>
        reject(err.response.data["msg"] || "An unknown error occurred")
      );
  });
};

export const getMemberRecord = () => {
  return new Promise<MemberRecord>((resolve, reject) => {
    axios
      .get("/api/memberrecord")
      .then((response) => resolve(response.data as MemberRecord))
      .catch((err) =>
        reject(err.response.data["msg"] || "An unknown error occurred")
      );
  });
};

export const authRequest = (cid: string, dob: string, next: string | null) => {
  return new Promise<{ success: boolean; emailHint: string }>(
    (resolve, reject) => {
      axios
        .post("/api/auth/request", { cid, dob, next })
        .then((response) => {
          if (response.data !== undefined) {
            resolve({
              success: response.data["success"],
              emailHint: response.data["emailHint"],
            });
          } else {
            reject("Success indicated but no data sent");
          }
        })
        .catch((err) => {
          reject(err.response.data["msg"] || "An unknown error occurred");
        });
    }
  );
};

export const authToken = (emailToken: string) => {
  return new Promise<{ cid: string; name: string } | null>(
    (resolve, reject) => {
      axios
        .post("/api/auth/token", { emailToken })
        .then((response) => {
          if (response.status === 200 && response.data.auth !== undefined) {
            const authData = response.data.auth;
            resolve({ cid: authData.cid, name: authData.name });
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          reject(err.response.data["msg"] || "An unknown error occurred");
        });
    }
  );
};

export const authInfo = () =>
  new Promise<{ cid: string; name: string } | null>((resolve, reject) => {
    axios
      .get("/api/auth/info")
      .then((response) => {
        if (response.data.auth !== undefined && response.data.auth !== null) {
          resolve({
            cid: response.data.auth.cid,
            name: response.data.auth.name,
          });
        } else {
          resolve(null);
        }
      })
      .catch(() => {
        reject("An error occurred getting auth info");
      });
  });

export const authLogout = () =>
  new Promise<void>((resolve, reject) => {
    axios
      .post("/api/auth/logout")
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject("An error occurred getting auth info");
      });
  });

export const renew = (
  confirmedDeclarations: boolean,
  renewalToken: string | null
) =>
  new Promise<void>((resolve, reject) => {
    axios
      .post("/api/renew", {
        declared: confirmedDeclarations,
        renewalToken,
      })
      .then(() => resolve())
      .catch((err) =>
        reject(err.response.data["msg"] || "An unknown error occurred")
      );
  });
